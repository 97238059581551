export const homepage_queue = [
    {
        fullName: 'Nathan Trimble',
        email: 'nathan128tri@yahoo.com',
        buildingName: 'Building 1',
        locationName: 'Unit 705',
        leaseId: 127056,
        daysLeft: -28,
        date: '6/18/2024',
        event: 'EXECUTE_MOVE_IN',
    },
    {
        fullName: 'Phoenix Baker',
        email: 'phx.bxtr@gmail.com',
        buildingName: 'Building 1',
        locationName: 'Unit 601',
        leaseId: 215643,
        daysLeft: 44,
        date: '7/9/2024',
        event: 'EXECUTE_MOVE_OUT',
    },
    {
        fullName: 'Ainsley Shertil',
        email: 'thshiller127@gmail.com',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 3',
        leaseId: 221490,
        daysLeft: 44,
        date: '7/9/2024',
        event: 'EXECUTE_MOVE_OUT',
    },
    {
        fullName: 'Shannon Trully',
        email: 's.trullu@mail.com',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 2',
        leaseId: 221491,
        daysLeft: 44,
        date: '7/9/2024',
        event: 'EXECUTE_MOVE_OUT',
    },
    {
        fullName: 'Olivia Rhye',
        email: 'olivia@gmail.com',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 1',
        leaseId: 228807,
        daysLeft: 46,
        date: '9/19/2024',
        event: 'EXECUTE_MOVE_IN',
    },
    {
        fullName: 'Olivia Rhye',
        email: 'olivia@gmail.com',
        buildingName: 'Building 1',
        locationName: 'Unit 303',
        leaseId: 240231,
        daysLeft: 46,
        date: '9/19/2024',
        event: 'EXECUTE_MOVE_OUT',
    },
    {
        fullName: 'Olivia Rhye',
        email: 'olivia@gmail.com',
        buildingName: 'Building 1',
        locationName: 'Unit 102',
        leaseId: 247023,
        daysLeft: 48,
        date: '9/21/2024',
        event: 'EXECUTE_MOVE_IN',
    },
    {
        fullName: 'Olivia Rhye',
        email: 'olivia@gmail.com',
        buildingName: 'Building 2',
        locationName: 'Unit 607',
        leaseId: 252964,
        daysLeft: 48,
        date: '9/21/2024',
        event: 'EXECUTE_MOVE_IN',
    },
    {
        fullName: 'Olivia Rhye',
        email: 'olivia@gmail.com',
        buildingName: 'Building 1',
        locationName: 'Unit 306',
        leaseId: 263211,
        daysLeft: 58,
        date: '10/3/2024',
        event: 'EXECUTE_MOVE_OUT',
    },
];
