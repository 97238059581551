import { computed } from 'vue';
import { useStore } from 'vuex';

export function useDigitalHumanAiMenuItem() {
    const store = useStore();

    const hasDHPermission = computed(() => store.getters['dh/hasPermission']);

    const digitalHumanAiMenuItems = computed(() => ({
        list: [
            {
                icon: 'power-outlet-2',
                id: '__digital_human_ai',
                label: 'Digital human ai',
                permissionChecker: hasDHPermission.value,
                children: [
                    {
                        label: 'Document ingestion',
                        id: 'knowledge_library.index',
                    },
                    {
                        label: 'Conversation topics & settings',
                        id: 'digital_human_ai_settings.index',
                    },
                    {
                        label: 'Unit availability & pricing',
                        id: 'dh.availability_pricing.index',
                    },
                ],
            },
        ],
    }));

    return { digitalHumanAiMenuItems };
}
