import { computed, ref } from 'vue';
import { useAccountingStore } from '@/stores/accounting';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';
import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';

export function useGeneralLedgerMenuItem() {
    const acctStore = useAccountingStore();
    const permissions = ref({
        journalEntriesTile: USER_PERMISSIONS.ACCT_VIEW_JOURNAL_ENTRIES_LIST,
        glAccountsTile: USER_PERMISSIONS.ACCT_VIEW_GL_ACCOUNTS_LIST,
        bankReconciliation: USER_PERMISSIONS.ACCT_VIEW_RECONCILE,
        bankAccountTile: USER_PERMISSIONS.ACCT_VIEW_BANK_ACCOUNTS_LIST,
        generalLedgerSettings: USER_PERMISSIONS.ACCT_VIEW_GL_SETTINGS,
        accountsReceivableSettings: USER_PERMISSIONS.ACCT_VIEW_AR_SETTINGS,
        depositsTile: USER_PERMISSIONS.ACCT_VIEW_DEPOSIT_LIST,
        chargesTile: USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST,
        invoicesTile: USER_PERMISSIONS.ACCT_VIEW_INVOICES,
        paymentsTile: USER_PERMISSIONS.ACCT_VIEW_PAYMENTS,
        applicationAndLeaseTile: LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT,
        processMoveInsTile: LEASING_PERMISSIONS.LEASE_MOVE_IN,
        renewalOffersTile: LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL,
        transferResidentTile: LEASING_PERMISSIONS.LEASE_TRANSFER_RESIDENTS,
        residentDetailsTileReadOnly: LEASING_PERMISSIONS.LEASE_RESIDENT_DETAILS_READ_ONLY,
        residentDetailsTileWithAccess: LEASING_PERMISSIONS.LEASE_RESIDENT_DETAILS_ACCESS,
        monthToMonthTile: LEASING_PERMISSIONS.LEASE_MONTH_TO_MONTH_MANAGEMENT,
        moveOutResidentsTile: LEASING_PERMISSIONS.LEASE_MOVE_OUT,
        noticeToVacantTile: LEASING_PERMISSIONS.LEASE_NTV,
    });

    const hasAccessToGeneralLedgerSection = computed(() => {
        return (
            hasAccessToGeneralLedgerTile.value ||
            acctStore.hasPermission(permissions.value.bankAccountTile) ||
            acctStore.hasPermission(permissions.value.glAccountsTile) ||
            acctStore.hasPermission(permissions.value.bankReconciliation) ||
            acctStore.hasPermission(permissions.value.journalEntriesTile)
        );
    });
    const hasAccessToGeneralLedgerTile = computed(() => {
        return (
            acctStore.hasPermission(permissions.value.generalLedgerSettings) ||
            acctStore.hasPermission(permissions.value.accountsReceivableSettings)
        );
    });
    const generalLedgerMenuItems = computed(() => ({
        list: [
            {
                icon: 'book-open',
                permissionChecker: hasAccessToGeneralLedgerSection.value,
                label: 'General ledger',
                id: '__general_ledger',
                children: [
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.journalEntriesTile),
                        label: 'Journal entries',
                        id: 'general_ledger.journal_entry_list',
                    },
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.glAccountsTile),
                        label: 'G/L accounts',
                        id: 'general_ledger.gl_account_list',
                    },
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.bankReconciliation),
                        label: 'Bank reconciliation',
                        id: 'general_ledger.bank_reconciliation_list',
                    },
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.bankAccountTile),
                        label: 'Bank accounts',
                        id: 'general_ledger.bank_account_list',
                    },
                    {
                        label: 'Accounting settings',
                        id: 'accounting.settings',
                    },
                ],
            },
        ],
    }));

    return { generalLedgerMenuItems };
}
