const routes = [
    {
        name: 'dashboard',
        path: '/dashboard',
        components: {
            default: () => import('@/views/report/Index'),
            ui_v3: () => import('@/views/report/v3/Index'),
        },
        meta: { stickyTopBar: true, title: 'Reporting', breadcrumbName: 'Reporting', actions: true },
    },
];

export default routes;
