import getEnvVariable from '@/utils/getEnvVariable';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import DataProvider from '@/api/DataProvider';
import httpClient from '@/api/httpClient';
import { isUndefined, omitBy } from 'lodash';
import store from '@/store';
import resources from './resources';
import MockAdapter from '@/api/adapters/MockAdapter';
import mocks from './mocks';

const overwritedApiUrl = () => {
    if (['QA', 'DEV'].includes(getEnvVariable('VUE_APP_ENV_NAME'))) {
        console.log(
            'change IoT url:',
            '\n',
            `localStorage.setItem('VUE_APP_IOT_API_URL', '${getEnvVariable('VUE_APP_IOT_API_URL')}')`,
            '\n',
            "remove IoT overwrite: localStorage.removeItem('VUE_APP_IOT_API_URL')"
        );

        return localStorage.getItem('VUE_APP_IOT_API_URL');
    }

    return null;
};

const baseURL = overwritedApiUrl() || getEnvVariable('VUE_APP_IOT_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => {
    return {
        ...requestConfig,
        responseType: requestConfig.params.responseType,
        headers: omitBy(
            {
                'X-Quext-Customer-Id': store.getters['auth/customerId'],
                'X-Quext-Community-Id': store.getters['auth/community']?.id,
                ...requestConfig.headers,
                'X-App-Id': 'spa-app',
            },
            isUndefined
        ),
    };
};

const afterRequestInterceptor = (data = {}) => {
    const {
        action,
        response: { headers, data: responseData },
    } = data;
    if (action === 'getList') {
        const totalSize = headers['x-total-count'];
        return {
            totalSize: totalSize,
            items: responseData,
        };
    }
    return responseData;
};

const mockAdapter = new MockAdapter(mocks);
const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor, afterRequestInterceptor);

export default new DataProvider({
    handle: (action, resource, params) => {
        if (resource.indexOf('mock_') === 0) return mockAdapter.handle(action, resource, params);

        return httpAdapter.handle(action, resource, params);
    },
});
