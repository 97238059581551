import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useLeasingStore } from '@/stores/leasing';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';
import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';

export function useResidentLifecycleMenuItem() {
    const store = useStore();

    const leasingStore = useLeasingStore();
    const isSuperAdmin = computed(() => store.getters['auth/isSuperAdmin']);
    const customerCommunityAppCodes = computed(() => store.getters['auth/customerCommunityAppCodes']);

    const showApplicationAndLeaseTile = computed(() => {
        if (isSuperAdmin.value) return true;

        const allAppsRelatedCommunity = customerCommunityAppCodes.value;
        const isUsingGO = allAppsRelatedCommunity.includes('GO');
        return allAppsRelatedCommunity.length && !isUsingGO;
    });
    const permissions = ref({
        journalEntriesTile: USER_PERMISSIONS.ACCT_VIEW_JOURNAL_ENTRIES_LIST,
        glAccountsTile: USER_PERMISSIONS.ACCT_VIEW_GL_ACCOUNTS_LIST,
        bankReconciliation: USER_PERMISSIONS.ACCT_VIEW_RECONCILE,
        bankAccountTile: USER_PERMISSIONS.ACCT_VIEW_BANK_ACCOUNTS_LIST,
        generalLedgerSettings: USER_PERMISSIONS.ACCT_VIEW_GL_SETTINGS,
        accountsReceivableSettings: USER_PERMISSIONS.ACCT_VIEW_AR_SETTINGS,
        depositsTile: USER_PERMISSIONS.ACCT_VIEW_DEPOSIT_LIST,
        chargesTile: USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST,
        invoicesTile: USER_PERMISSIONS.ACCT_VIEW_INVOICES,
        paymentsTile: USER_PERMISSIONS.ACCT_VIEW_PAYMENTS,
        applicationAndLeaseTile: LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT,
        processMoveInsTile: LEASING_PERMISSIONS.LEASE_MOVE_IN,
        renewalOffersTile: LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL,
        transferResidentTile: LEASING_PERMISSIONS.LEASE_TRANSFER_RESIDENTS,
        residentDetailsTileReadOnly: LEASING_PERMISSIONS.LEASE_RESIDENT_DETAILS_READ_ONLY,
        residentDetailsTileWithAccess: LEASING_PERMISSIONS.LEASE_RESIDENT_DETAILS_ACCESS,
        monthToMonthTile: LEASING_PERMISSIONS.LEASE_MONTH_TO_MONTH_MANAGEMENT,
        moveOutResidentsTile: LEASING_PERMISSIONS.LEASE_MOVE_OUT,
        noticeToVacantTile: LEASING_PERMISSIONS.LEASE_NTV,
    });
    const hasAccessToResidentLifecycleSection = computed(() => {
        const requiredPermissions = [
            'processMoveInsTile',
            'renewalOffersTile',
            'applicationAndLeaseTile',
            'residentDetailsTileReadOnly',
            'residentDetailsTileWithAccess',
            'monthToMonthTile',
            'moveOutResidentsTile',
            'noticeToVacantTile',
        ];

        return requiredPermissions.some(permission => leasingStore.hasPermission(permissions.value[permission]));
    });
    const hasPartnerDocPermission = computed(() => store.getters['partner_documents/hasPermission']);

    const checkLeasingPermission = permissionAlias => {
        return leasingStore.hasPermission(permissionAlias);
    };
    const residentLifecycleMenuItems = computed(() => ({
        list: [
            {
                icon: 'user',
                id: '__resident_lifecycle',
                label: 'Resident lifecycle',
                permissionChecker: hasAccessToResidentLifecycleSection.value,
                children: [
                    {
                        permissionChecker:
                            showApplicationAndLeaseTile.value && checkLeasingPermission(permissions.value.applicationAndLeaseTile),
                        label: 'Applications and lease',
                        id: 'manage_application.index',
                    },
                    {
                        permissionChecker: hasPartnerDocPermission.value,
                        label: 'Partner documents',
                        id: 'manage-partner-documents.index',
                    },
                    {
                        permissionChecker: checkLeasingPermission(permissions.value.processMoveInsTile),
                        label: 'Process move-ins',
                        id: 'leases.index',
                    },
                    {
                        permissionChecker: checkLeasingPermission(permissions.value.renewalOffersTile),
                        label: 'Renewals',
                        id: 'renewals.index',
                    },
                    {
                        permissionChecker: false,
                        label: 'Transfer resident',
                        id: 'transfer_resident.index',
                    },
                    {
                        permissionChecker:
                            checkLeasingPermission(permissions.value.residentDetailsTileReadOnly) ||
                            checkLeasingPermission(permissions.value.residentDetailsTileWithAccess),
                        label: 'Resident details',
                        id: 'manage_residents.index',
                    },
                    {
                        permissionChecker: checkLeasingPermission(permissions.value.monthToMonthTile),
                        label: 'Month-to-month',
                        id: 'monthToMonth.index',
                    },
                    {
                        permissionChecker: checkLeasingPermission(permissions.value.moveOutResidentsTile),
                        label: 'Move out residents',
                        id: 'move_out.index',
                    },
                    {
                        permissionChecker: checkLeasingPermission(permissions.value.noticeToVacantTile),
                        label: 'Notice to vacate',
                        id: 'notice_to_vacate.index',
                    },
                ],
            },
        ],
    }));

    return { residentLifecycleMenuItems };
}
