<template>
    <QxtHeader
        size="small"
        :title="route.meta?.title || ''"
        status=""
        class="border-b-0 pl-8"
    >
        <template #actions>
            <GlobalSearch />

            <PropertySelect />
            <ProfileMenu class="ml-4" />
            <LayoutSwitch class="ml-4" />
        </template>
        <template #default>
            <slot>
                <div id="top-nav-bar" />
            </slot>
        </template>
    </QxtHeader>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { QxtHeader } from '@asmartment/qui-base';
import LayoutSwitch from '@/components/layout/v3/LayoutSwitch.vue';
import PropertySelect from '@/components/layout/v3/PropertySelect.vue';
import GlobalSearch from '@/views/search/v3/GlobalSearch.vue';
import ProfileMenu from '@/components/layout/v3/ProfileMenu.vue';

const route = useRoute();
</script>
