import { shallowRef, computed } from 'vue';
import { sortBy } from 'lodash-es';
import { useStore } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import AuthDataProvider from '@/api/auth';

export default function useCustomerOptions() {
    const store = useStore();
    const { notifyError } = useAlertStore();

    const customers = shallowRef([]);

    const profile = computed(() => store.getters['auth/profile']);
    const customerId = computed(() => (customerContext.value ? profile.value?.customerId : profile.value?.profileId) ?? null);
    const customerContext = computed(() => store.getters['auth/isSuperAdmin'] || store.getters['auth/isQuextUser']);

    const customerOptions = computed(() =>
        customerContext.value
            ? [
                {
                    id: undefined,
                    name: 'Unselect company',
                },
                ...customers.value,
            ]
            : customers.value
    );

    async function fetchCustomers() {
        if (customerContext.value) {
            await fetchCustomerOptions();
        } else {
            await fetchProfileOptions();
        }
    }

    async function fetchCustomerOptions() {
        try {
            const { content = [] } = await AuthDataProvider.adapter.get('customers', {
                size: 999,
                nonDeleted: true,
                sort: 'name,asc',
            });
            customers.value = content.map(item => ({
                ...item,
                id: item.customerId,
            }));
        } catch (error) {
            notifyError(error);
        }
    }

    async function fetchProfileOptions() {
        try {
            const { customerUsers = [] } = await AuthDataProvider.adapter.get('profile', {
                nonDeleted: true,
            });
            customers.value = sortBy(
                customerUsers.map(({ firstName, lastName, customerName, ...item }) => ({
                    ...item,
                    customerName,
                    firstName,
                    lastName,
                    fullName: `${firstName} ${lastName} (${customerName ? customerName : 'super admin'})`,
                    name: customerName,
                    id: item.profileId,
                })),
                ['customerName']
            );
        } catch (error) {
            notifyError('Failed to fetch data for profile context selector');
        }
    }

    return {
        customers,
        customerId,
        customerContext,
        customerOptions,
        fetchCustomers,
    };
}
