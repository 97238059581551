const routes = [
    {
        name: 'home',
        path: '/',
        components: {
            default: () => import('@/views/search/Index'),
            ui_v3: () => import('@/views/homepage/Index'),
        },
        meta: {
            title: 'Home',
        },
        children: [
            {
                name: 'home',
                path: '/',
                components: {
                    default: () => import('@/views/search/Index'),
                    demo: () => import('@/views/homepage/v3/Index'),
                },
                children: [
                    {
                        name: 'home.iot.container',
                        path: '/home/iot',
                        component: () => import('@/components/layout/v3/BackCard'),
                        props: { backPageName: 'home', title: 'Move-in / Move-out' },
                        children: [
                            {
                                name: 'home.iot.move',
                                path: '/home/iot/move',
                                component: () => import('@/views/iot/residents_queue/v3/Index'),
                                props: { title: 'All' },
                            },
                            {
                                name: 'home.iot.battery',
                                path: '/home/iot/battery',
                                component: () => import('@/views/homepage/v3/MockListCommon'),
                                props: {
                                    filtersGroup: [
                                        ['All', ''],
                                        ['Critical', 'Critical'],
                                        ['Low', 'Low'],
                                        ['Caution', 'Caution'],
                                    ],
                                },
                                meta: { subtitle: 'Battery' },
                            },
                            {
                                name: 'home.iot.devices',
                                path: '/home/iot/devices',
                                component: () => import('@/views/homepage/v3/MockList'),
                                props: { pageSize: 100, filtersGroupByValue: '' },
                                meta: { subtitle: 'Devices' },
                            },
                            {
                                name: 'home.leasing.occupancy',
                                path: '/home/leasing/occupancy',
                                component: () => import('@/views/homepage/v3/MockListCommon'),
                                props: {
                                    filtersGroup: [
                                        ['All', ''],
                                        ['Pending', 'Pending'],
                                        ['Status', 'Status'],
                                    ],
                                    type: 'leasing',
                                },
                                meta: { subtitle: 'Occupancy' },
                            },
                            {
                                name: 'home.leasing.renewals',
                                path: '/home/leasing/renewals',
                                component: () => import('@/views/homepage/v3/MockListCommon'),
                                props: {
                                    filtersGroup: [
                                        ['All', ''],
                                        ['Pending', 'Pending'],
                                        ['Status', 'Status'],
                                    ],
                                    type: 'leasing',
                                },
                                meta: { subtitle: 'Renewals' },
                            },
                            {
                                name: 'home.iot.runtime',
                                path: '/home/iot/runtime',
                                component: () => import('@/views/homepage/v3/MockListCommon'),
                                props: {
                                    type: 'runtime',
                                },
                                meta: { subtitle: 'Set Point Runtime' },
                            },
                            {
                                name: 'home.iot.access',
                                path: '/home/iot/access',
                                component: () => import('@/views/homepage/v3/MockListCommon'),
                                props: {
                                    filtersGroup: [
                                        ['All', ''],
                                        ['Morning', 'Morning'],
                                        ['Afternoon', 'Afternoon'],
                                        ['Evening', 'Evening'],
                                    ],
                                    type: 'accesses',
                                },
                                meta: { subtitle: 'Popular Access' },
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default routes;
