import { pickBy, filter } from 'lodash-es';
import { v4 } from 'uuid';

const deepObjectValues = obj => {
    return Object.values(obj).flatMap(value => {
        if (typeof value === 'object') {
            return deepObjectValues(value);
        }

        return value;
    });
};

class MockAdapter {
    data = undefined;

    constructor(data) {
        this.data = data;
    }

    async handle(action, resource, params) {
        if (!this[action]) {
            throw Error(`Bad action ${resource}:${action}`);
        }

        return new Promise(resolve => {
            setTimeout(() => {
                const result = this[action](this.data, resource, params);
                resolve(result);
            }, 1000);
        });
    }

    // eslint-disable-next-line no-unused-vars
    getList(data, resource, params = {}) {
        return this.getCollection(data, resource, params);
    }

    getOne(data, resource, params) {
        const [record] = this.getRecord(data, resource, params.id);

        return record;
    }

    create(data, resource, params) {
        const collection = this.getCollection(data, resource, params);

        const record = {
            id: v4(),
            ...params.data,
        };

        collection.push(record);

        return record;
    }

    update(data, resource, params) {
        const [, index] = this.getRecord(data, resource, params.data.id);
        return (data[resource][index] = params.data);
    }

    patch(data, resource, params) {
        const [oldRecord, index] = this.getRecord(data, resource, params.data.id);
        return (data[resource][index] = {
            ...oldRecord,
            ...params.data,
        });
    }

    delete(data, resource, params) {
        const collection = this.getCollection(data, resource);
        data[resource] = collection.filter(record => record.id !== params.id);
    }

    getCollection(data, resource, params = {}) {
        const match = pickBy(params, param => param != null && typeof param !== 'object');
        delete match.q;
        delete match.limit;
        delete match.size;
        delete match.page;

        const collection = filter(data[resource], match).filter(item => {
            if (!params.q) {
                return true;
            }

            return deepObjectValues(item).some(value => typeof value === 'string' && value.toLowerCase().includes(params.q.toLowerCase()));
        });

        if (!collection) {
            throw Error(`Bad resource '${resource}'`);
        }

        return collection;
    }

    getRecord(data, resource, id) {
        const collection = this.getCollection(data, resource);

        const index = collection.findIndex(record => record.id === id);

        if (index < 0) {
            throw Error(`Record ${id} of resource '${resource}' is not found`);
        }

        return [collection[index], index];
    }
}

export default MockAdapter;
