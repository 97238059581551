export const homepage_report_runtime = [
    {
        deviceType: 'Thermostat',
        id: '00:00:00:00:00:00:00:00',
        buildingName: 'Building 1',
        locationName: 'Unit 705',
        battery: 12,
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 6,
        current: 72,
        point: 67,
        status: '3+ hours',
    },
    {
        deviceType: 'Thermostat',
        id: '00:00:00:00:00:00:00:01',
        buildingName: 'Building 1',
        locationName: 'Unit 601',
        battery: 11,
        vendor: 'Allegion',
        format: 'Be467b',
        lastSeen: 13,
        current: 75,
        point: 69,
        status: '3+ hours',
    },
    {
        deviceType: 'Thermostat',
        id: 'a7:aa:aa:00:01:00:03:a0',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 3',
        battery: 5,
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 48,
        current: 78,
        point: 71,
        status: '1 hour',
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:03:a5',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 2',
        battery: 12,
        vendor: 'Allegion',
        format: 'Be467b',
        lastSeen: 3,
        current: 77,
        point: 73,
        status: '3+ hours',
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:03:a0',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 1',
        battery: 18,
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 4,
        current: 79,
        point: 69,
        status: '3+ hours',
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:03:a3',
        buildingName: 'Building 1',
        locationName: 'Unit 303',
        battery: 28,
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 2,
        current: 78,
        point: 71,
        status: '2 hours',
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:08:a0',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 1',
        battery: 48,
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 8,
        current: 79,
        point: 69,
        status: '3+ hours',
    },
];

export const homepage_report_battery = [
    {
        deviceType: 'Thermostat',
        id: '00:00:00:00:00:00:00:00',
        buildingName: 'Building 1',
        locationName: 'Unit 705',
        battery: 12,
        status: 'Low',
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 6,
    },
    {
        deviceType: 'Lock',
        id: 'a20000000f14228',
        buildingName: 'Building 1',
        locationName: 'Unit 601',
        battery: 11,
        status: 'Low',
        vendor: 'Allegion',
        format: 'Be467b',
        lastSeen: 13,
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:03:a4',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 3',
        battery: 13,
        status: 'Low',
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 4,
    },
    {
        deviceType: 'Lock',
        id: 'a20000000f14242',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 2',
        battery: 2,
        status: 'Critical',
        vendor: 'Allegion',
        format: 'Be467b',
        lastSeen: 4,
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:03:a7',
        buildingName: 'Building 2',
        locationName: 'Unit 204 - Bedroom 1',
        battery: 8,
        status: 'Critical',
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 2,
    },
    {
        deviceType: 'Thermostat',
        id: 'aa:aa:aa:00:01:00:03:a0',
        buildingName: 'Building 1',
        locationName: 'Unit 303',
        battery: 28,
        status: 'Caution',
        vendor: 'Quext',
        format: 'Be467b',
        lastSeen: 4,
    },
    {
        deviceType: 'Lock',
        id: 'a37000000f14227',
        buildingName: 'Building 1',
        locationName: 'Unit 102',
        battery: 22,
        status: 'Caution',
        vendor: 'Allegion',
        format: 'Be467b',
        lastSeen: 12,
    },
    {
        deviceType: 'Lock',
        id: 'a20000000f14227',
        buildingName: 'Building 2',
        locationName: 'Unit 607',
        battery: 24,
        status: 'Caution',
        vendor: 'Allegion',
        format: 'Be467b',
        lastSeen: 32,
    },
];

export const homepage_report_accesses = [
    {
        doorName: 'Main Entry 3',
        buildingName: 'Building 1',
        unlocks: 216,
        battery: 72,
        vendor: 'PDK',
        format: 'Be467b',
        status: 'Morning',
    },
    {
        doorName: 'B2-Front',
        buildingName: 'Building 2',
        unlocks: 124,
        battery: 11,
        vendor: 'Allegion',
        format: 'Be467b',
        status: 'Afternoon',
    },
    {
        doorName: 'B3-Front',
        buildingName: 'Building 3',
        unlocks: 110,
        battery: 43,
        vendor: 'Brivo',
        format: 'Be467b',
        status: 'Morning',
    },
    {
        doorName: 'Fitness Center',
        buildingName: 'Building 1',
        unlocks: 98,
        battery: 81,
        vendor: 'Allegion',
        format: 'Be467b',
        status: 'Morning',
    },
    {
        doorName: 'Pool',
        buildingName: 'Amenity',
        unlocks: 46,
        battery: 3,
        vendor: 'Yale',
        format: 'Be467b',
        status: 'Evening',
    },
    {
        doorName: 'Lounge',
        buildingName: 'Building 1',
        unlocks: 19,
        battery: 91,
        vendor: 'Brivo',
        format: 'Be467b',
        status: 'Evening',
    },
];

export const homepage_report = [
    {
        unitName: '2210',
        location: 'Building 1103, Floor 2',
        device: 'Sensor',
        commStatus: 'Online',
        powerStatus: 'Low',
        signalHealth: [88, -2],
        error: 'Bathroom Sensor',
        tenants: ['Jamal Jones', 'Hillary S.'],
    },
    {
        unitName: '1304',
        location: 'Building 1103, Floor 3',
        device: 'Lock',
        commStatus: 'Online',
        powerStatus: 'Critical',
        signalHealth: [96, 3],
        error: 'Error 11.6721',
        tenants: ['Henry Leech', 'Lisa Wells'],
    },
    {
        unitName: '2101',
        location: 'Building 2101, Floor 1',
        device: 'Lock',
        commStatus: 'Offline',
        powerStatus: 'Full',
        signalHealth: [12, -4],
        error: 'Error 12.1238',
        tenants: ['Courtney Platte', 'Stefani K.'],
    },
    {
        unitName: '1518',
        location: 'Building 1105, Floor 2',
        device: 'Lock',
        commStatus: 'Online',
        powerStatus: 'Low',
        signalHealth: [10, 5],
        error: 'Bathroom Sensor',
        tenants: ['Linda Walsh'],
    },
    {
        unitName: '1304',
        location: 'Building 1001, Floor 3',
        device: 'Lock',
        commStatus: 'Offline',
        powerStatus: 'Low',
        signalHealth: [32, -4],
        error: 'Error 11.6721',
        tenants: ['Henry Ford', 'Tom Hedge'],
    },
    {
        unitName: '777',
        location: 'Building 2000, Floor 1',
        device: 'Sensor',
        commStatus: 'Online',
        powerStatus: 'Full',
        signalHealth: [80, -4],
        error: 'Error 12.1238',
        tenants: ['Courtney Love', 'Linda Walsh'],
    },
    {
        unitName: '2289',
        location: 'Building 2403, Floor 1',
        device: 'Lock',
        commStatus: 'Online',
        powerStatus: 'Critical',
        signalHealth: [75, -5],
        error: 'Bathroom Sensor',
        tenants: ['Hillary S.', 'Linda Walsh'],
    },
    {
        unitName: '1478',
        location: 'Building 1544, Floor 3',
        device: 'Lock',
        commStatus: 'Online',
        powerStatus: 'Critical',
        signalHealth: [15, -8],
        error: 'Error 11.6721',
        tenants: ['Henry Leech', 'Lisa Wells'],
    },
    {
        unitName: '2528',
        location: 'Building 2101, Floor 1',
        device: 'Lock',
        commStatus: 'Offline',
        powerStatus: 'Full',
        signalHealth: [80, 2],
        error: 'Error 12.1238',
        tenants: ['Courtney Platte', 'Linda Walsh'],
    },
    {
        unitName: '2108',
        location: 'Building 1455, Floor 1',
        device: 'Lock',
        commStatus: 'Offline',
        powerStatus: 'Full',
        signalHealth: [60, 3],
        error: 'Error 12.1238',
        tenants: ['Stefani K.'],
    },
    {
        unitName: '25289',
        location: 'Building 21, Floor 3',
        device: 'Thermostat',
        commStatus: 'Offline',
        powerStatus: 'Full',
        signalHealth: [80, 2],
        error: 'Sync Error',
        tenants: [],
    },
    {
        unitName: '210811',
        location: 'Building 1, Floor 4',
        device: 'Thermostat',
        commStatus: 'Offline',
        powerStatus: 'Full',
        signalHealth: [60, 3],
        error: 'Comm Error',
        tenants: ['Stefani K.'],
    },
    {
        unitName: '1304-88',
        location: 'Building 1103, Floor 3',
        device: 'Thermostat',
        commStatus: 'Online',
        powerStatus: 'Critical',
        signalHealth: [96, 3],
        error: 'Battery Error',
        tenants: ['Henry Leech', 'Lisa Wells'],
    },
];

export const homepage_report_leasing = [
    {
        id: '1004',
        unit: '1004',
        building: 'Building 1, Floor 1',
        residents: ['Rudy Arrambide Jr.', 'Oscar Silva'],
        leaseStartDate: '04/29/2024',
        leaseEndDate: '04/28/2025',
        moveInDate: '05/10/2024',
        status: 'Pending',
    },
    {
        id: '1108',
        unit: '1108',
        building: 'Building 1, Floor 1',
        residents: ['Ariann Schilling'],
        leaseStartDate: '04/26/2024',
        leaseEndDate: '04/25/2025',
        moveInDate: '05/10/2024',
        status: 'Status',
    },
    {
        id: '1014',
        unit: '1014',
        building: 'Building 1, Floor 1',
        residents: ['Jay Bradhsaw'],
        leaseStartDate: '04/25/2024',
        leaseEndDate: '04/24/2025',
        moveInDate: '05/10/2024',
        status: 'Pending',
    },
    {
        id: '1208',
        unit: '1208',
        building: 'Building 1, Floor 2',
        residents: ['Gina Schreiber'],
        leaseStartDate: '04/25/2024',
        leaseEndDate: '04/24/2025',
        moveInDate: '05/10/2024',
        status: 'Status',
    },
    {
        id: '1230',
        unit: '1230',
        building: 'Building 2, Floor 3',
        residents: ['Joshua Ayala', 'Leslie Taylor', 'Christina Tavor'],
        leaseStartDate: '04/25/2024',
        leaseEndDate: '04/24/2025',
        moveInDate: '05/10/2024',
        status: 'Pending',
    },
    {
        id: '0415',
        unit: '0415',
        building: 'Building 0, Floor 1',
        residents: ['Leslie Taylor', 'Label'],
        leaseStartDate: '04/26/2024',
        leaseEndDate: '04/25/2025',
        moveInDate: '05/10/2024',
        status: 'Status',
    },
    {
        id: '2312',
        unit: '2312',
        building: 'Building 2, Floor 3',
        residents: ['Christina Tavor'],
        leaseStartDate: '04/25/2024',
        leaseEndDate: '04/24/2025',
        moveInDate: '05/10/2024',
        status: 'Pending',
    },
    {
        id: '2106',
        unit: '2106',
        building: 'Building 2, Floor 3',
        residents: ['Label', 'John Doe'],
        leaseStartDate: '04/24/2024',
        leaseEndDate: '04/23/2025',
        moveInDate: '05/10/2024',
        status: 'Status',
    },
];
