<template>
    <QxtSideNav
        :company="companyProperties"
        :community="communityProperties"
        :core-services="coreServices"
        :services="menu"
        :menu="menu"
        :profile="profileProperties"
        :addons="[
            {
                icon: 'message-square',
                id: '1',
                label: 'Chat with Support',
            },
        ]"
        :model-value="routeName"
        @update:model-value="updateActiveNavItem"
        @update:company="handleCustomerSelect"
        @update:community="handleCommunitySelect"
    />
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useDevToolsMenuItem } from '@/components/layout/v3/navigation/menu_items/devTools';
import { useSearchEngine } from '@/components/layout/v3/navigation/menu_items/searchEngine';
import { useEmailBotItem } from '@/components/layout/v3/navigation/menu_items/emailBot';
import { useIotMenuItem } from '@/components/layout/v3/navigation/menu_items/iot';
import { useVendorsMenuItem } from '@/components/layout/v3/navigation/menu_items/vendors';
import { useAccountsReceivableMenuItem } from '@/components/layout/v3/navigation/menu_items/accountsReceivable';
import { useAccountsPayableMenuItem } from '@/components/layout/v3/navigation/menu_items/accountsPayable';
import { useGeneralLedgerMenuItem } from '@/components/layout/v3/navigation/menu_items/generalLedger';
import { usePortfolioSetupMenuItem } from '@/components/layout/v3/navigation/menu_items/portfolioSetup';
import { useQuextSetupMenuItem } from '@/components/layout/v3/navigation/menu_items/quextSetup';
import { useResidentLifecycleMenuItem } from '@/components/layout/v3/navigation/menu_items/residentLifecycle';
import { useCommunitySettingsMenuItem } from '@/components/layout/v3/navigation/menu_items/communitySettings';
import { usePhysicalPropertyLifecycleMenuItem } from '@/components/layout/v3/navigation/menu_items/physicalPropertyLifecycle';
import { useDigitalHumanAiMenuItem } from '@/components/layout/v3/navigation/menu_items/digitalHumanAi';
import { useDigitalHumanMenuItem } from '@/components/layout/v3/navigation/menu_items/digitalHuman';
import { useMarketingMenuItem } from '@/components/layout/v3/navigation/menu_items/marketing';
import { useWebsitesMenuItem } from '@/components/layout/v3/navigation/menu_items/websites';
import { useHomeMenuItem } from '@/components/layout/v3/navigation/menu_items/home';
import { QxtSideNav } from '@asmartment/qui-base';
import { useAlertStore } from '@/stores/alert';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners';
import IntegrationsPartnersDataProvider from '@/api/integration_partner';
import { useRouter, useRoute } from 'vue-router';
import usePropertySelect from '@/components/layout/v3/composables/usePropertySelect';

const router = useRouter();
const route = useRoute();
const store = useStore();
const ipsStore = useIntegrationPartnersStore();
const { notifyError } = useAlertStore();
const { companyProperties, communityProperties, profileProperties, handleCustomerSelect, handleCommunitySelect } = usePropertySelect();

const clearWebsiteInformation = () => store.dispatch('website_info/clearWebsiteInformation');

const filterMenuItems = menuItems => {
    return menuItems.reduce((filtered, item) => {
        if (!Object.hasOwn(item, 'permissionChecker') || item.permissionChecker === true) {
            const filteredChildren = item.children
                ? item.children.reduce((childFiltered, child) => {
                    if (!Object.hasOwn(child, 'permissionChecker') || child.permissionChecker === true) return [...childFiltered, child];
                    return childFiltered;
                }, [])
                : undefined;

            if (!filteredChildren || filteredChildren.length > 0) {
                filtered.push({
                    ...item,
                    children: filteredChildren,
                });
            }
        }

        return filtered;
    }, []);
};

const { devToolsMenuItems } = useDevToolsMenuItem();
const { searchEngineMenuItems } = useSearchEngine();
const { emailBotItems } = useEmailBotItem();
const { iotMenuItems } = useIotMenuItem();
const { vendorsMenuItems } = useVendorsMenuItem();
const { accountsReceivableMenuItems } = useAccountsReceivableMenuItem();
const { accountsPayableMenuItems } = useAccountsPayableMenuItem();
const { generalLedgerMenuItems } = useGeneralLedgerMenuItem();
const { portfolioSetupMenuItems } = usePortfolioSetupMenuItem();
const { quextSetupMenuItems } = useQuextSetupMenuItem();
const { residentLifecycleMenuItems } = useResidentLifecycleMenuItem();
const { communitySettingsMenuItems } = useCommunitySettingsMenuItem();
const { physicalPropertyLifecycleMenuItems } = usePhysicalPropertyLifecycleMenuItem();
const { digitalHumanAiMenuItems } = useDigitalHumanAiMenuItem();
const { digitalHumanMenuItems } = useDigitalHumanMenuItem();
const { marketingMenuItems } = useMarketingMenuItem();
const { websitesMenuItems } = useWebsitesMenuItem();
const { homeMenuItems } = useHomeMenuItem();

const routeName = computed(() => route.name);

const updateActiveNavItem = navItem => {
    if (!navItem.id.startsWith('__')) {
        router.push({ name: navItem.id, params: navItem.data?.routeParams });
    }
};

const coreServices = computed(() =>
    homeMenuItems.value.list.filter(item => {
        if (Object.hasOwn('permissionChecker')) {
            return item.permissionChecker;
        }
        return true;
    })
);

const menu = computed(() =>
    [
        {
            list: [
                ...websitesMenuItems.value.list,
                ...marketingMenuItems.value.list,
                ...digitalHumanMenuItems.value.list,
                ...digitalHumanAiMenuItems.value.list,
                ...physicalPropertyLifecycleMenuItems.value.list,
                ...communitySettingsMenuItems.value.list,
                ...residentLifecycleMenuItems.value.list,
                ...quextSetupMenuItems.value.list,
                ...portfolioSetupMenuItems.value.list,
                ...generalLedgerMenuItems.value.list,
                ...accountsPayableMenuItems.value.list,
                ...accountsReceivableMenuItems.value.list,
                ...vendorsMenuItems.value.list,
                ...iotMenuItems.value.list,
                ...emailBotItems.value.list,
                ...searchEngineMenuItems.value.list,
                ...devToolsMenuItems.value.list,
            ],
        },
    ].reduce((filteredSections, section) => {
        const filteredItems = filterMenuItems(section.list);

        if (filteredItems.length > 0) {
            filteredSections.push({
                ...section,
                list: filteredItems,
            });
        }

        return filteredSections;
    }, [])
);

onMounted(() => {
    clearWebsiteInformation();
    IntegrationsPartnersDataProvider.adapter
        .get('permissions')
        .then(ipsPermissions => {
            ipsStore.setIpsV2Permissions(ipsPermissions);
        })
        .catch(error => notifyError(error));
});
</script>
